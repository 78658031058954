<template>
  <v-card>
    <v-card-title class="text-h5 font-weight-regular"> Filter CBS List </v-card-title>
    <v-card-text>
      <v-row justify="center" class="ma-5">
        <v-col md="6">
          <v-combobox
            v-model="formValues['type']"
            :items="typeOptions"
            label="Type"
            class="pa-0 mb-1"
            outlined
            hide-details
          />
        </v-col>
        <v-col md="6">
          <v-combobox
            class="pa-0 mb-1"
            outlined
            hide-details
            v-model="formValues['status']"
            :items="statusOptions"
            label="Status"
          />
        </v-col>
        <v-col md="6">
          <v-combobox
            class="pa-0 mb-1"
            outlined
            hide-details
            v-model="formValues['review']"
            :items="reviewOptions"
            label="Review"
          />
        </v-col>
        <v-col md="6">
          <v-combobox
            class="pa-0 mb-1"
            outlined
            hide-details
            v-model="formValues['amlMatched']"
            :items="amlOptions"
            label="AML Matched"
          />
        </v-col>
        <v-col md="6" v-for="item in Object.keys(formItems)" :key="item">
          <v-text-field
            class="pa-0 mb-1"
            outlined
            hide-details
            :label="prettify(item)"
            v-model="formValues[item]"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="filterSelected"> Filter </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  name: "Filters",
  props: {
    initialValues: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mounted() {
    this.formValues = this.initialValues || {};
  },
  data() {
    return {
      formValues: {},
      formItems: {
        accountCode: "string",
        amlReferenceNo: "string",
        dob: "2022-04-12",
        firstName: "string",
        lastName: "string",
        middleName: "string",
        nid: "string",
        startTime: 0,
        stopTime: 0,
        surName: "string",
      },
      typeOptions: ["CBS_SELF"],
      statusOptions: ["APPROVED", "REJECTED"],
      amlOptions: ["true", "false"],
      reviewOptions: ["APPROVED"],
    };
  },
  methods: {
    filterSelected() {
      if (this.formValues["status"]) {
        this.formValues["status"] = _.upperCase(this.formValues["status"]);
      }
      if (this.formValues["type"]) {
        this.formValues["type"] = "MFS_" + _.upperCase(this.formValues["type"]);
      }
      this.$emit("filterSelected", this.formValues);
    },
    prettify(name) {
      return _.startCase(name);
    },
  },
};
</script>

<style scoped></style>
